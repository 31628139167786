import React from 'react';


const Map = () => {
  return (
    <>
      <iframe
            data-aos="zoom-in-up"
            data-aos-duration="2000"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2457.5294802392295!2d96.1227610660583!3d16.843698408544284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c193695dd6ed71%3A0xf2b15322347c98c1!2sMyanmar%20Play%20Store!5e0!3m2!1sen!2sth!4v1717252655476!5m2!1sen!2sth"
            width="100%"
            height="100%"
            style={{ borderRadius: "10px" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade">
      </iframe>
    </>
  );
};

export default Map;


